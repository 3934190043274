<template>
  <div>
    <v-container>
      <div class="d-flex align-center">
        <v-img
          alt="Золотое сечение"
          contain
          src="@/assets/logo-circle.png"
          max-height="230"
        />
      </div>
      <p class="text-h5 mt-5 text-center font-weight-light">
        Заявка слушателя Фонда «Золотое сечение»
      </p>
      <v-row class="mt-4" justify="center" align="center">
        <v-card
          tile
          :class="'px-' + horizontalPaddingValue + ' width-86p py-15 pb-15'"
          max-width="1600"
        >
        <p :class="'px-' + horizontalPaddingValue">
            Просим вас заполнить анкету, указав необходимые данные.<br /><br />
            По всем возникающим вопросам, пожалуйста, пишите на электронную
            почту:
            <a href="mailto:kpk@zsfond.ru" class="font-weight-light"
              >kpk@zsfond.ru</a
            >
          </p>
          <v-form ref="regForm" class="mt-4" v-model="isValid">
            <p class="text-h6 font-weight-light mt-3">Слушатель</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
               <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.firstName"
                  :rules="[rules.required]"
                  label="Имя"
                  hint="Введите имя"
                  color="#af955c"
                  dense
                  :error="!qualsInfo.firstName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.lastName"
                  :rules="[rules.required]"
                  label="Фамилия"
                  hint="Введите фамилию"
                  color="#af955c"
                  dense
                  :error="!qualsInfo.lastName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
               
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.middleName"
                  :rules="middleNameRules"
                  label="Отчество"
                  hint="Введите отчество"
                  :error="qualsInfo.middleName_missing ? false : !qualsInfo.middleName"
                  dense 
                  :required="!qualsInfo.middleName_missing"
                  color="#af955c"
                  :disabled="qualsInfo.middleName_missing"
                  ref="inputRef"
                ></v-text-field>
               
              </v-col>
            </v-row>

            <v-row class="mt-4" justify="center" align="center">
               <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.birthdayDate"
                  label="Дата рождения"
                  hint="Укажите дату рождения"
                  color="#af955c"
                  dense
                  type="date"
                  min="1900-01-01"
                  max="2200-12-31"
                  :error="!qualsInfo.birthdayDate"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-select
                  v-model="qualsInfo.gender"
                  :items="genders"
                  :rules="[rules.required]"
                  label="Пол"
                  hint="Укажите пол"
                  color="#af955c"
                  dense
                  :error="!qualsInfo.gender"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-select>
              </v-col>

               <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-checkbox label="Отчество отсутствует"
                  v-model="qualsInfo.middleName_missing"
                   color="#af955c"
                  @change="onMiddleNameMissingChange"
                ></v-checkbox>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Данные для входа</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.login"
                  :rules="[rules.required, rules.minLogin]"
                  label="Логин"
                  hint="Введите логин"
                  color="#af955c"
                  dense
                  required
                  :error="!qualsInfo.login"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.password"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.minPassword]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Пароль"
                  hint="Введите пароль"
                  dense
                  required
                  :error="!qualsInfo.password"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.passwordRepeated"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    rules.required,
                    rules.passwordMatch(
                      qualsInfo.password,
                      qualsInfo.passwordRepeated
                    ),
                  ]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Подтверждение пароля"
                  hint="Введите пароль еще раз"
                  required
                  :error="!qualsInfo.passwordRepeated"
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Образование</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-select
                  v-model="qualsInfo.education_levelid"
                  label="Уровень образования"
                  hint="Укажите уровень образования"
                  color="#af955c"
                  dense
                  :items="educationLevel"
                  :disabled="isLoading"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :error="!qualsInfo.education_levelid"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-select
                  v-model="qualsInfo.qualification_categ"
                  label="Категория"
                  hint="Введите категория"
                  color="#af955c"
                  dense
                  :items="category"
                  :rules="[rules.required]"
                  required
                  :error="!qualsInfo.qualification_categ"
                  :disabled="isRegistrationSuccess"
                ></v-select>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Контактная информация</p>
            <v-divider></v-divider>
            <p class="text-overline mt-3 ml-6">Контакты</p>
            <v-divider inset></v-divider>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  @copy.prevent
                  @paste.prevent
                  v-model="qualsInfo.contacts.email"
                  :rules="[rules.required, rules.email, rules.emailCorrect]"
                  label="E-mail"
                  hint="Введите e-mail адрес"
                  color="#af955c"
                  dense
                  :error="!qualsInfo.contacts.email"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  @copy.prevent
                  @paste.prevent
                  v-model="emailRetry"
                  :rules="[
                    rules.required,
                    rules.email,
                    rules.emailMatch(qualsInfo.contacts.email, emailRetry),
                  ]"
                  label="Подверждение E-mail"
                  hint="Введите e-mail адрес еще раз"
                  color="#af955c"
                  dense
                  :error="!emailRetry"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="qualsInfo.contacts.phone"
                  :rules="[rules.required, rules.phone]"
                  label="Телефон"
                  hint="Введите номер телефона"
                  v-mask="'+7(###)###-##-##'"
                  color="#af955c"
                  dense
                  type="phone"
                  :error="
                    !qualsInfo.contacts.phone ||
                    qualsInfo.contacts.phone === '+7('
                  "
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="text-overline mt-3 ml-6">Адрес регистрации</p>
            <v-divider inset></v-divider>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                <v-autocomplete
                  v-model="qualsInfo.contacts.address_reg"
                  label="Адрес регистрации"
                  hint="Введите адрес регистрации"
                  :loading="isLoading"
                  :items="regAddressItems"
                  item-text="fullAddress"
                  item-value="id"
                  no-filter
                  :search-input.sync="regAddressSearch"
                  hide-no-data
                  hide-details
                  :rules="[rules.required]"
                  :error="!qualsInfo.contacts.address_reg"
                  required
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="4" sm="4" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="qualsInfo.contacts.address_reg_house"
                  :rules="[rules.required]"
                  label="Дом"
                  hint="Введите номер дома"
                  color="#af955c"
                  :error="!qualsInfo.contacts.address_reg_house"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="qualsInfo.contacts.address_reg_building"
                  label="Корпус"
                  hint="Введите корпус/строение (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="qualsInfo.contacts.address_reg_flat"
                  label="Квартира"
                  hint="Введите номер квартиры (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
      
          </v-form>
        </v-card>
      </v-row>
      <v-row class="mt-5 mx-4" justify="center" align="center">
  <v-col cols="12" sm="10" max-width="1920px">
        <v-checkbox
          v-model="isUserAgreedPolicyFirst"
          color="#af955c"
          :readonly="isRegistrationSuccess || !politika_operatora"
        >
          <template v-slot:label>
            <div>
              Продолжая регистрацию, я подтверждаю ознакомление с
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="/docs/politika_operatora.pdf"
                    @click.stop="politika_operatora = true"
                    v-on="on"
                    >Политикой оператора в отношении обработки персональных данных</a
                  >
                </template>
                Обработка персональных данных производится<br />в соответствии с
                требованиями 152-ФЗ и 242-ФЗ
              </v-tooltip>
            
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
          v-model="isUserAgreedPolicySecond"
          color="#af955c"
          :readonly="isRegistrationSuccess || !agreement_quals"
        >
          <template v-slot:label>
            <div>
              Продолжая регистрацию, я даю согласие на обработку персональных данных на условиях
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="/docs/agreement_quals.pdf"
                    @click.stop="agreement_quals = true"
                    v-on="on"
                    >Пользовательского соглашения</a
                  >
                </template>
                Обработка персональных данных производится<br />в соответствии с
                требованиями 152-ФЗ и 242-ФЗ
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      </v-row>
      <v-row class="mb-5" justify="center" align="center">
        <v-btn
          class="mr-5"
          outlined
          large
          color="#af955c"
          :to="{ name: 'SignIn' }"
        >
          На главную
        </v-btn>
        <v-btn
          outlined
          large
          :disabled="!isValid || !isUserAgreedPolicyFirst || !isUserAgreedPolicySecond || isRegistrationSuccess"
          color="#af955c"
          @click="registration"
          >Зарегистрироваться</v-btn
        >
      </v-row>
      <v-snackbar v-model="resultSnackbar" vertical multi-line timeout="-1">
        <span v-html="resultText"></span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="error"
            text
            v-bind="attrs"
            @click="
              () => {
                resultSnackbar = false;
                resultText = '';
              }
            "
          >
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <modal-dialog
      :isVisible="isRegistrationSuccess"
      title="Регистрация"
      text="Вы успешно зарегистрировались в личном кабинете. Для продолжения работы, пожалуйста, авторизуйтесь в системе."
      rightButtonText="Авторизоваться"
      :rightButtonAction="routeToMainPage"
    >
    </modal-dialog>
  </div>
</template>

<script>
import axios from "axios";
import _debounce from "debounce";
import CustomModalDialog from "../../components/ModalDialog.vue";

import qualsInfo from "../../model/qualsInfo";
import inputRules from "../../model/inputRules";
import gendersEnum from "../../model/genders";
import gradesEnum from "../../model/grades";

export default {
  name: "RegistrationView",
  data: () => ({
    isValid: false,
    isLoading: false,
    dateMenu: false,

    isPasswordVisible: false,
    isParentTwoVisible: false,
    isGeneralEducationVisible: true,
    isProfessionalEducationVisible: false,

    isRegistrationSuccess: false,

    genders: gendersEnum,

    regAddressSearch: null,
    levelSearch: null,
    regAddressItems: [],
    educationLevel: [],

    category: ["Первая", "Высшая", "Нет"],

    resultSnackbar: false,
    resultText: "",

    isUserAgreedPolicyFirst: false,
    isUserAgreedPolicySecond: false,

    qualsInfo: JSON.parse(JSON.stringify(qualsInfo)),
    generalEducationTypes: [],
    professionalEducationTypes: [],
    grades: gradesEnum,

    emailRetry: "",
    foreignLanguages: [],

    rules: { ...inputRules },
    token: null,
    politika_operatora: false,
    agreement_quals: false,
  }),
  components: {
    "modal-dialog": CustomModalDialog,
  },
  computed: {
    qualsInfoStringify() {
  //    this.qualsInfo.documents.snils.value;
      return JSON.stringify(this.qualsInfo);
    },
    middleNameRules() {
      
      // if (this.qualsInfo.middleName_missing == false) {
      //   this.qualsInfo.middleName = null;

      // } else {
      //   this.$refs.inputRef.clearableCallback();
      //   [this.rules.required];
      // }
     return this.qualsInfo.middleName_missing ? [] : [this.rules.required];
    },
    horizontalPaddingValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 5;
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 10;
      }
    },
    debouncedRegAddressSearch() {
      return _debounce(this.searchRegAddress, 700);
    },
    debouncedFactAddressSearch() {
      return _debounce(this.searchFactAddress, 700);
    },
    debounceEducationLevelSearch() {
      return _debounce(this.searchEducationLevel, 700);
    },
  },
  methods: {
    onMiddleNameMissingChange() {
      this.qualsInfo.middleName = null;
    },
    getToken() {
      let tokenElements = document.getElementsByName(
        "__RequestVerificationToken"
      );
      if (tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
    async registration() {
      this.$emit("set-processing", true);
      try {
        let regData = new FormData();

        regData.append("qualsInfo", this.qualsInfoStringify);
        let response = await axios.post("/api/postQualsRegistration", regData, {
          headers: {
            "Content-Type": "multipart/form-data",
            RequestVerificationToken: this.token,
          },
        });
        this.getRegistrationResponse(response.data);
      } catch (error) {
        let data = {
          success: false,

          message: error.message /*error.message*/, //"Ошибка регистрации. Пожалуйста, повторите попытку или обратитесь в службу поддержки.",
        };
        this.getRegistrationResponse(data);
      }
    },
    getRegistrationResponse(data) {
      this.resultText = "";
      this.resultSnackbar = false;

      this.$emit("set-processing", false);

      if (data.success === true) {
        this.isRegistrationSuccess = true;
      } else {
        this.isRegistrationSuccess = false;
        this.resultText = data.message;
        this.resultSnackbar = true;
      }
    },

    async searchAddress(searchInput) {
      this.isLoading = true;
      let items = [];
      try {
        let response = await axios.get(
          "/api/getAddresses?query=" + encodeURIComponent(searchInput),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        items = response.data;
      } catch (error) {
        items = [];
      } finally {
        this.isLoading = false;
      }
      return items;
    },
    async searchRegAddress() {
      const items = (await this.searchAddress(this.regAddressSearch)) || [];
      this.regAddressItems = items;
    },
    async getEducationLevel() {
      this.isLoading = true;
      try {
        let response = await axios.get("/api/getEducationLevel", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.educationLevel = response.data;
        this.educationLevel.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (error) {
        this.educationLevel = [];
      } finally {
        this.isLoading = false;
      }
    },
    
    setFirstNumAtPhones() {
      this.qualsInfo.contacts.phone = "+7(";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    routeToMainPage() {
      this.$router.push("/").catch(() => {});
    },
  },
  watch: {
    qualsInfoStringify: {
      handler(val, oldVal) {
        const newqualsInfo = JSON.parse(val);
        const oldqualsInfo = JSON.parse(oldVal);
      },
    },
    regAddressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedRegAddressSearch();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "HowTo") {
      this.$emit("increment-cache-key");
    }
    next();
  },
  created() {
    this.getToken();
    this.getEducationLevel();
  },
  mounted() {
    this.setFirstNumAtPhones();
  },
};
</script>
<style scoped>
.width-86p {
  width: 86%;
}
</style>
